<template>
    <div class="mini_game_bet">
        <div class="mini_game">
            <div class="m1 dmr">
                <div class="mini_fram">
                    <leisure-game-ifram-view>
                        <iframe id="donghang-gamefram" scrolling="no" frameborder="0"
                                src="http://ntry.com/scores/power_ladder/live.php"></iframe>
                    </leisure-game-ifram-view>
                </div>
            </div>
            <div class="m2">
                <div class="game_tab">
                    <leisure-bet-masker :show-masker="showMasker"></leisure-bet-masker>
                    <leisure-timer-comp
                            ref="ltimer"
                            :leisure-game-info="leisureGameInfo"
                            @timerStop="timerStop" @refreshTimer="refreshTimer">
                        <span slot="title" class="text-orange"><i class="fa fa-star"></i>파워사다리 </span>
                    </leisure-timer-comp>

                    <!--선택부분-->
                    <div class="mini_bet_box">
                        <div class="mg">
                            <span class="t">홀/짝</span>
                            <div class="mg_btn b2  bg-blue"
                                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_POWERSADALI_ODDEVEN && selectedInfo.selectedWay === 1}"
                                 @click="selected(leisureConst.LEISURE_ATTRIBUTE_POWERSADALI_ODDEVEN,leisureGameInfo.leisureGame.id,1, oddevenConfig.odds1, oddevenConfig, '홀')">
                                <div class="r rblue">
                                    <span class="n">홀</span>
                                    <span class="b">{{oddevenConfig.odds1}}</span>
                                </div>
                            </div>
                            <div class="mg_btn b2 bg-red"
                                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_POWERSADALI_ODDEVEN && selectedInfo.selectedWay === 2}"
                                 @click="selected(leisureConst.LEISURE_ATTRIBUTE_POWERSADALI_ODDEVEN,leisureGameInfo.leisureGame.id,2,  oddevenConfig.odds2, oddevenConfig, '짝')">
                                <div class="r rred">
                                    <span class="n">짝</span>
                                    <span class="b">{{oddevenConfig.odds2}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="mg">
                            <span class="t">좌/우 출발</span>
                            <div class="mg_btn b2 bg-blue"
                                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_POWERSADALI_LEFT_RIGHT && selectedInfo.selectedWay === 1}"
                                 @click="selected(leisureConst.LEISURE_ATTRIBUTE_POWERSADALI_LEFT_RIGHT,leisureGameInfo.leisureGame.id,1, leftrightConfig.odds1, leftrightConfig, '좌출발')">
                                <div class="r rblue">
                                    <span class="n">좌</span>
                                    <span class="b">{{leftrightConfig.odds1}}</span>
                                </div>
                            </div>
                            <div class="mg_btn b2 bg-red"
                                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_POWERSADALI_LEFT_RIGHT && selectedInfo.selectedWay === 2}"
                                 @click="selected(leisureConst.LEISURE_ATTRIBUTE_POWERSADALI_LEFT_RIGHT,leisureGameInfo.leisureGame.id,2, leftrightConfig.odds2, leftrightConfig, '우출발')">
                                <div class="r rred">
                                    <span class="n">우</span>
                                    <span class="b">{{leftrightConfig.odds2}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="mg">
                            <span class="t">3줄/4줄</span>
                            <div class="mg_btn b2 bg-blue"
                                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_POWERSADALI_LINE_COUNT && selectedInfo.selectedWay === 1}"
                                 @click="selected(leisureConst.LEISURE_ATTRIBUTE_POWERSADALI_LINE_COUNT,leisureGameInfo.leisureGame.id,1, lineConfig.odds1, lineConfig, '3줄')">
                                <div class="r rblue">
                                    <span class="n">3줄</span>
                                    <span class="b">{{lineConfig.odds1}}</span>
                                </div>
                            </div>
                            <div class="mg_btn b2 bg-red"
                                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_POWERSADALI_LINE_COUNT && selectedInfo.selectedWay === 2}"
                                 @click="selected(leisureConst.LEISURE_ATTRIBUTE_POWERSADALI_LINE_COUNT,leisureGameInfo.leisureGame.id,2, lineConfig.odds2, lineConfig, '4줄')">
                                <div class="r rred">
                                    <span class="n">4줄</span>
                                    <span class="b">{{lineConfig.odds2}}</span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <!--파워볼 베팅카트-->
                    <leisure-bet-cart-comp
                            ref="betCart" :kind="kind"
                            :selected-info="selectedInfo">
                        파워사다리
                    </leisure-bet-cart-comp>

                </div>
            </div>
            <div class="m3">


                <!--최근 배팅내역-->
                <leisure-lately-bet-list-comp
                        ref="latelyBetList"
                        :kind="kind"></leisure-lately-bet-list-comp>

            </div>


        </div>
    </div>
</template>

<script>
    import SubTitle from "../../../components/SubTitle";
    import LeisureTimerComp from "../../../components/leisuregame/LeisureTimerComp";
    import LeisureBetCartComp from "../../../components/leisuregame/LeisureBetCartComp";
    import leisureConst from "../../../common/leisureConst";
    import sportsConst from "../../../common/sportsConst";
    import LeisureBetMasker from "../../../components/leisuregame/LeisureBetMasker";
    import {leisureGameMixin} from "../../../common/mixin";
    import {getLeisureGameAttributeConfig} from "../../../network/leisureRequest";
    import LeisureLatelyBetListComp from "../../../components/leisuregame/LeisureLatelyBetListComp";
    import LeisureGameView from "../../../components/leisuregame/LeisureGameView";
    import LeisureGameLinks from "../LeisureGameLinks";
    import LeisureGameIframView from "../LeisureGameIframView";
    import LeisureCompGameLinks from "../LeisureCompDonghangGameLinks";
    import {RECEIVE_CURRENTLEISUREGAMECOMP, RECEIVE_CURRENTLEISUREGAMEID} from "../../../store/mutation-types";

    export default {

        name: "PowerSadali",
        mixins: [leisureGameMixin],
        components: {
            LeisureCompGameLinks,
            LeisureGameIframView,
            LeisureGameLinks,
            LeisureGameView,
            LeisureLatelyBetListComp, LeisureBetMasker, LeisureBetCartComp, LeisureTimerComp, SubTitle
        },
        data() {
            return {
                kind: leisureConst.LEISURE_KIND_POWERSADALI,

            }
        },

        methods: {
            initLeisureGameArttributeConfig() {
                getLeisureGameAttributeConfig().then(res => {
                    if (res.data.success) {
                        this.attrConfig = res.data.data

                        //배당설정
                        this.oddevenConfig = this.attrConfig.find(attr => {
                            return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_POWERSADALI_ODDEVEN)
                        })

                        this.leftrightConfig = this.attrConfig.find(attr => {
                            return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_POWERSADALI_LEFT_RIGHT)
                        })

                        this.lineConfig = this.attrConfig.find(attr => {
                            return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_POWERSADALI_LINE_COUNT)
                        })
                    }
                })
            }
        },
        created() {
            this.initKindConfig(this.kind)
            this.$store.commit(RECEIVE_CURRENTLEISUREGAMECOMP,leisureConst.LEISURE_COMP_DONGHANG);
            this.$store.commit(RECEIVE_CURRENTLEISUREGAMEID,this.kind);
        }

    }
</script>

<style scoped>
    #gamefram {
        width: 830px;
        height: 640px;
    }

    @media screen and (max-width: 1024px) {
        #gamefram {
            border: 0 !important;
            position: absolute !important;
            --transform: scale(0.40) !important;
            transform: scale(0.6, 0.5) !important;
            left: -305px;
            top: -150px;
        }

        .mini_game .mini_fram {
            min-height: 350px !important;
            height: 350px !important;
        }
    }
    @media screen and (max-width: 360px) {
        #gamefram {
            border: 0 !important;
            position: absolute !important;
            transform: scale(0.6, 0.5) !important;
            left: -330px;
            top: -150px;
        }
    }
    @media screen and (max-width: 375px) {
        #gamefram {
            border: 0 !important;
            position: absolute !important;
            transform: scale(0.6, 0.5) !important;
            left: -302px;
            top: -150px;
        }
    }
    @media screen and (max-width: 415px) {
        #gamefram {
            border: 0 !important;
            position: absolute !important;
            transform: scale(0.6, 0.5) !important;
            left: -250px;
            top: -150px;
        }
    }
</style>